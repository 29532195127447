import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { authSelector } from '../../store/feature/authSlice';
import Cookies from 'js-cookie';

const Sub_footer = ({ title }) => {
  const { login, user_details } = useSelector(authSelector);
  const router = useRouter();
  const is_draft = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  const [isAccount, setIsAccount] = useState();
  const [accountId, setAccountID] = useState();
  useEffect(() => {
    axios
      .get('/api/a/my_account', {
        params: {
          page: 1,
          type: 'accounts',
          user_id: user_details.id,
        },
      })
      .then((res) => {
        if (res?.data?.accounts.length > 0) {
          setIsAccount(true);
          setAccountID(res?.data?.accounts[0].id);
        } else {
          setIsAccount(false);
        }
      });
  }, [router, user_details]);
  return (
    <div className="w-full bg-white my-20 ">
      <div className="max-w-[50%] mx-auto px-4 py-4 flex flex-col items-center gap-3">
        <h3 className="h3 ">
          {is_draft
            ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_title ||
              'Cant find what you need?'
            : TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_title ||
              'Cant find what you need?'}
        </h3>
        <p className="paragraph text-center">
          {is_draft
            ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_description ||
              'Build your own listings in a matter of hours - weather just for your own user or others too.'
            : TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_description ||
              'Build your own listings in a matter of hours - weather just for your own user or others too.'}
        </p>
        <button
          className=" font-medium text-xl text-primary "
          onClick={() => {
            if (login) {
              if (isAccount) {
                const link = is_draft
                  ? TYPE_CONSTANT.DRAFT_STYLES?.general
                      ?.sub_footer_button_link || {
                      pathname: '/a/add-listing',
                      query: { account_id: accountId },
                    }
                  : TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_button_link || {
                      pathname: '/a/add-listing',
                      query: { account_id: accountId },
                    };
                router.push(link);
              } else {
                router.push('/a/create-account');
              }
            } else {
              router.push(`/sign-in?to=${router.asPath}`);
            }
          }}
        >
          {is_draft
            ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_button_title ||
              title
            : TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_button_title || title}
        </button>
      </div>
    </div>
  );
};

export default Sub_footer;
