import React from 'react';
import Head from 'next/head';
import Header5 from '../../components/Header/Header5';
import Footer from '../../components/Footer/Footer';
import CustomFooter from '../../components/Footer/CustomFooter';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <div>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={`${pageDescription}`} />
        <meta property="og:title" content={`${pageTitle}`} key="title" />
      </Head>
      <div>
        <div>
          <Header5 />
        </div>
        <div className="  min-h-screen  ">
          <div>{children}</div>
        </div>
        <div className="  bg-footer mt-16 md:mt-20   min-h-[200px]  left-0 bottom-0  ">
          <CustomFooter />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
