import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { check_login } from '../../constant/check_auth';
import { refreshPage } from '../../store/feature/authSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
// import { profile_order_details } from '../../tradly.config.js';
import ProductMainLayout from '../../themes/product/MainLayout';
import EventMainLayout from '../../themes/event/MainLayout';
import SimpleMainLayout from '../../themes/simple/MainLayout';
import EtsyMainLayout from '../../themes/Art & Craft/MainLayout';
import SaasMainLayout from '../../themes/saas/CustomLayout';
import CrowdfundingMainLayout from '../../themes/Crowdfunding/MainLayout';

import ProductOrderDetailsPageLayout from '../../themes/product/OrderDetailsPageLayout';
import EventOrderDetailsPageLayout from '../../themes/event/OrderDetailsPageLayout';
import SaasOrderDetailsPageLayout from '../../themes/saas/OrderDetailsPageLayout';
import SimpleOrderDetailsPageLayout from '../../themes/simple/OrderDetailsPageLayout';

const order_details = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_refresh_key`)) {
      dispatch(
        refreshPage({
          key: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_refresh_key`),
        })
      );
    }
  }, [dispatch]);

  const profile_order_details = () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventOrderDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasOrderDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductOrderDetailsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrderDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SimpleOrderDetailsPageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrderDetailsPageLayout />
          </ProductMainLayout>
        );
    }
  };

  return <>{check_login(router) && profile_order_details()}</>;
};

export default order_details;
