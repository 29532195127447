import React, { useEffect } from 'react';
import Link from 'next/link';
import tradly from "tradly"

const SigninButton = () => {
  return (
    <Link href="/sign-in">
      <button className="px-4 py-2 rounded-full  text-base font-semibold  transition duration-400 hover:bg-[#22222213]">
        Sign in
      </button>
    </Link>
  );
};

export default SigninButton;

// import { Dialog, Transition } from '@headlessui/react';
// import { Fragment, useState } from 'react';
// import SignInForm from '../SigninForm/SignInForm';
// import SignInForm2 from '../SigninForm/SignInForm2';

// export default function SigninButton() {
//   let [isOpen, setIsOpen] = useState(true);

// const [general_configs, setGeneral_configs] = useState(null);
// useEffect(() => {
//    tradly.app
//     .getConfigList({
//       paramBody: 'general',
//     })
//     .then((res) => {
//       if (!res.error) {
//         setGeneral_configs(res?.data?.configs);
//       }
//     });
// }, [0]);

//   function closeModal() {
//     setIsOpen(false);
//   }

//   function openModal() {
//     setIsOpen(true);
//   }

//   return (
//     <>
//       <div className=" ">
//         <button
//           type="button"
//           onClick={openModal}
//           className="px-4 py-2 rounded-full  text-base font-semibold  transition duration-400 hover:bg-[#22222213]"
//         >
//           Sign in
//         </button>
//       </div>

//       <Transition appear show={isOpen} as={Fragment}>
//         <Dialog
//           as="div"
//           className="fixed inset-0 z-10 overflow-y-auto bg-[#42424241]"
//           onClose={closeModal}
//         >
//           <div className="min-h-screen px-4 text-center">
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <Dialog.Overlay className="fixed inset-0" />
//             </Transition.Child>

//             {/* This element is to trick the browser into centering the modal contents. */}
//             <span
//               className="inline-block h-screen align-middle"
//               aria-hidden="true"
//             >
//               &#8203;
//             </span>
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 scale-95"
//               enterTo="opacity-100 scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 scale-100"
//               leaveTo="opacity-0 scale-95"
//             >
//               <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-button">
//                 <Dialog.Title
//                   as="h3"
//                   className="text-lg font-medium leading-6 text-gray-900"
//                 >
//                   Payment successful
//                 </Dialog.Title>
//                 <div className="mt-2">
//                   <SignInForm2 general_configs={general_configs} />
//                 </div>

//                 <div className="mt-4">
//                   <button
//                     type="button"
//                     className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-button hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
//                     onClick={closeModal}
//                   >
//                     Got it, thanks!
//                   </button>
//                 </div>
//               </div>
//             </Transition.Child>
//           </div>
//         </Dialog>
//       </Transition>
//     </>
//   );
// }
