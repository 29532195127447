import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import SearchBox2 from '../SearchBox/SearchBox2';
import { getThumbnailImage } from '../Shared/Constant/Constant';
import HeaderNav from './HeaderNav';
import HeaderCategories2 from './HeaderCategories2';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';

const Header5 = () => {
  const [logo, setLogo] = useState(null);
  const [login, setLogin] = useState(false);
  useEffect(() => {
    setLogo(localStorage.getItem('logo'));
    setLogin(localStorage.getItem('login'));
  }, [0]);

  return (
    <div className=" mt-0 ">
      {/* logo */}
      <div className="max-w-[1400px] mx-auto px-3 lg:px-9 py-3 flex items-center justify-between gap-3">
        <div className="flex-none">
          <Link href="/">
            <div className="block">
              {logo && (
                <Link href="/" passHref={true}>
                  <a className=" flex items-center   relative cursor-pointer ">
                    <img
                      src={logo && getThumbnailImage(logo)}
                      className={
                        TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ===
                          undefined ||
                        (TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width ===
                          undefined &&
                          ' object-contain ')
                      }
                      style={{
                        height: `${
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height || 50
                        }px`,
                        width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                      }}
                      alt="logo"
                    />
                  </a>
                </Link>
              )}
            </div>
          </Link>
        </div>
        <div className="hidden lg:block flex-grow   ">
          <SearchBox2 />
        </div>
        {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
          <div className="hidden lg:block  ">
            <Add_listing_button title={'Add Listing'} />
          </div>
        )}
        <div className="flex-none">
          <HeaderNav />
        </div>
      </div>
      <div className="hidden lg:block mt-1 min-h-[12px]  relative after:absolute after:border-b-2 after:border-[#22222226] after:left-0 after:right-0 after:bottom-0  after:-z-10">
        {/* <HeaderCategories2 /> */}
      </div>
      <div className=" flex items-center justify-between px-3 lg:hidden gap-3 mb-3">
        <button className=" flex-none p-3 rounded-full  transition duration-400  hover:bg-[#22222213]  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
        <div className=" flex-grow   ">
          <SearchBox2 />
        </div>
      </div>
    </div>
  );
};

export default Header5;
