module.exports = {
  booking: (
    <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="  mr-3  sm:mr-5 text-[#6e686e] mt-[2px]"
        width="24"
        height="20"
        viewBox="0 0 20 14"
        fill="none"
      >
        <path
          d="M7 5C6.73478 5 6.48043 5.10536 6.29289 5.29289C6.10536 5.48043 6 5.73478 6 6V8C6 8.26522 6.10536 8.51957 6.29289 8.70711C6.48043 8.89464 6.73478 9 7 9C7.26522 9 7.51957 8.89464 7.70711 8.70711C7.89464 8.51957 8 8.26522 8 8V6C8 5.73478 7.89464 5.48043 7.70711 5.29289C7.51957 5.10536 7.26522 5 7 5ZM19 6C19.2652 6 19.5196 5.89464 19.7071 5.70711C19.8946 5.51957 20 5.26522 20 5V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6C1.26522 6 1.51957 6.10536 1.70711 6.29289C1.89464 6.48043 2 6.73478 2 7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8C18.7348 8 18.4804 7.89464 18.2929 7.70711C18.1054 7.51957 18 7.26522 18 7C18 6.73478 18.1054 6.48043 18.2929 6.29289C18.4804 6.10536 18.7348 6 19 6ZM18 4.18C17.4208 4.3902 16.9205 4.77363 16.5668 5.27816C16.2132 5.7827 16.0235 6.38388 16.0235 7C16.0235 7.61612 16.2132 8.2173 16.5668 8.72184C16.9205 9.22637 17.4208 9.6098 18 9.82V12H8C8 11.7348 7.89464 11.4804 7.70711 11.2929C7.51957 11.1054 7.26522 11 7 11C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12H2V9.82C2.57915 9.6098 3.07954 9.22637 3.43316 8.72184C3.78678 8.2173 3.97648 7.61612 3.97648 7C3.97648 6.38388 3.78678 5.7827 3.43316 5.27816C3.07954 4.77363 2.57915 4.3902 2 4.18V2H6C6 2.26522 6.10536 2.51957 6.29289 2.70711C6.48043 2.89464 6.73478 3 7 3C7.26522 3 7.51957 2.89464 7.70711 2.70711C7.89464 2.51957 8 2.26522 8 2H18V4.18Z"
          fill="#6e686e"
        />
      </svg>
      <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 hover:text-primary">
        My Orders
      </span>
    </div>
  ),

  order: (
    <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6  mr-3  sm:mr-5 text-[#6e686e] mt-[2px]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
        />
      </svg>
      <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 hover:text-primary">
        My Orders
      </span>
    </div>
  ),
};


 